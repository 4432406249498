.mat-form-field {
  &.no-spacing {
    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-underline {
      bottom: 0;
    }
  }
}
