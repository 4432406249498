@use '@angular/material' as mat;
@import 'node_modules/bootstrap/scss/bootstrap-reboot';
@import 'node_modules/bootstrap/scss/utilities';
@import 'node_modules/bootstrap/scss/grid';
@import 'styles/form';
@import 'styles/snackbar';
@import 'styles/variables';

@import 'theme';

html {
  font-size: 16px;
  line-height: 16px;
  position: relative;
}

body {
  background: #fff;
  font-family: 'Red Hat Text', sans-serif;
  height: 100%;
  min-height: 100vh;
  min-width: $app-min-width;

  &.open-form {
    height: 0;
    min-height: 0;
    overflow: hidden;
  }
}

.primary-link {
  color: mat.get-color-from-palette($primary-map, 500);
  cursor: pointer;

  &:hover {
    color: mat.get-color-from-palette($primary-map, 400);
  }
}
