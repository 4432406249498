@use '@angular/material' as mat;
@import 'styles/theming';

@include mat.core();

$background-override: (
  disabled-button: map-get($secondary-map, 50)
);

$background: map-get($theme, background);
$background: map_merge($background, $background-override);

$foreground-override: (
  disabled-button: map-get($secondary-map, 200),
  text: map-get($secondary-map, 500)
);

$foreground: map-get($theme, foreground);
$foreground: map_merge($foreground, $foreground-override);

$theme: map_merge(
  $theme,
  (
    background: $background,
    foreground: $foreground
  )
);

@include mat.all-component-themes($theme);

$patient-portal-typography: mat.define-typography-config(
  $font-family: 'Red Hat Text, sans-serif',
  $button: mat.define-typography-level(16px, 16px, 500)
);

@include mat.all-component-typographies($patient-portal-typography);

body {
  color: map-get($secondary-map, 500);
}

button {
  outline: none;

  &:focus {
    outline: none;
  }

  &.mat-flat-button.mat-button-base:not(.mat-primary):not(.mat-accent):not(.mat-warn) {
    color: map-get($primary-map, 500);

    &:hover {
      color: map-get($primary-map, 400);
    }

    &:focus {
      color: map-get($primary-map, 900);
    }
  }

  &.mat-primary {
    background-color: map-get($primary-map, 500);

    &:hover {
      background-color: map-get($primary-map, 400);
    }

    &:focus {
      background-color: map-get($primary-map, 900);
    }

    &.light {
      background-color: map-get($primary-map, 50);
      border: 1px solid map-get($primary-map, 50);
      border-radius: 0.6rem;
      color: map-get($secondary-map, 500);
      font-weight: 500;
      line-height: 2rem;

      &:hover {
        border-color: map-get($primary-map, 500);
      }

      &:focus {
        background-color: map-get($primary-map, 900);
        border-color: map-get($primary-map, 900);
        color: #fff;
      }
    }
  }

  &.mat-warn {
    border: 1px solid map-get($warn-map, 500);
    line-height: 2rem;

    &:not(.reversed) {
      &:hover {
        background-color: map-get($warn-map, 400);
        box-shadow: 0 0 8px 0 map-get($warn-map, 500);
      }

      &:focus {
        background-color: map-get($warn-map, 900);
        border-color: map-get($warn-map, 900);
      }
    }

    &.reversed {
      background-color: #fff;
      border: solid 1px transparent;
      border-radius: 0.5rem;
      color: map-get($warn-map, 900);
      font-weight: 500;
      line-height: 2rem;

      &:hover {
        border-color: map-get($warn-map, 900);
      }

      &:focus {
        background-color: map-get($warn-map, 900);
        border-color: map-get($warn-map, 900);
        color: #fff;
      }
    }
  }
}

.mat-form-field-appearance-legacy {
  &.mat-form-field-disabled {
    .mat-form-field-underline {
      background-size: 1px 100%;
    }
  }

  .mat-form-field-label {
    color: map-get($secondary-map, 500);
  }

  .mat-hint {
    color: map-get($secondary-map, 300);
  }
}

mat-dialog-container {
  &.mat-dialog-container {
    padding: 0.5rem 0.5rem 1rem 0.5rem;
  }
}

.mat-radio-button {
  .mat-radio-container {
    height: 1rem;
    width: 1rem;

    .mat-radio-outer-circle {
      border-width: 1px;
      border-color: map-get($primary-map, 500);
      height: 1rem;
      width: 1rem;
    }
  }

  &.mat-primary {
    .mat-radio-inner-circle {
      height: 1rem;
      width: 1rem;
    }
  }
}

mat-checkbox {
  .mat-checkbox-frame {
    border-color: map-get($primary-map, 500);
    border-width: 1px;
  }
}
